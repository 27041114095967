var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "vxe-modal",
    {
      ref: "manyVehicleMonitorRef",
      attrs: {
        width: "35%",
        height: "700px",
        title: _vm.title,
        "show-zoom": "",
        resize: "",
        "lock-view": false,
        mask: false,
        "esc-closable": true,
        position: { left: "30%" },
        remember: "",
        transfer: "",
      },
      on: { close: _vm.handleCloseDeviceBind },
      model: {
        value: _vm.visible,
        callback: function ($$v) {
          _vm.visible = $$v
        },
        expression: "visible",
      },
    },
    [
      _c(
        "div",
        { staticClass: "register-contaion" },
        [
          _c(
            "a-form-model",
            { ref: "form", attrs: { model: _vm.form, rules: _vm.rules } },
            [
              _c(
                "a-form-model-item",
                { attrs: { label: "当前保养时间", prop: "upkeepTime" } },
                [
                  _c("a-date-picker", {
                    staticStyle: { width: "100%" },
                    attrs: {
                      placeholder: "请输入当次保养时的时间",
                      "value-format": "YYYY-MM-DD HH:mm:ss",
                      format: "YYYY-MM-DD HH:mm:ss",
                      "allow-clear": "",
                    },
                    model: {
                      value: _vm.form.upkeepTime,
                      callback: function ($$v) {
                        _vm.$set(_vm.form, "upkeepTime", $$v)
                      },
                      expression: "form.upkeepTime",
                    },
                  }),
                ],
                1
              ),
              _c(
                "a-form-model-item",
                { attrs: { label: "当前保养里程(KM)", prop: "odom" } },
                [
                  _c("a-input", {
                    attrs: { placeholder: "请输入保养时的里程" },
                    model: {
                      value: _vm.form.odom,
                      callback: function ($$v) {
                        _vm.$set(_vm.form, "odom", $$v)
                      },
                      expression: "form.odom",
                    },
                  }),
                ],
                1
              ),
              _c(
                "a-form-model-item",
                {
                  attrs: { label: "下次保养时间周期(月)", prop: "cycleUpkeep" },
                },
                [
                  _c("a-input", {
                    attrs: { placeholder: "请输入下次保养所需时间周期" },
                    model: {
                      value: _vm.form.cycleUpkeep,
                      callback: function ($$v) {
                        _vm.$set(_vm.form, "cycleUpkeep", $$v)
                      },
                      expression: "form.cycleUpkeep",
                    },
                  }),
                ],
                1
              ),
              _c(
                "a-form-model-item",
                {
                  attrs: { label: "下次保养里程周期(KM)", prop: "odomUpkeep" },
                },
                [
                  _c("a-input", {
                    attrs: { placeholder: "请输入下次车辆保养里程周期" },
                    model: {
                      value: _vm.form.odomUpkeep,
                      callback: function ($$v) {
                        _vm.$set(_vm.form, "odomUpkeep", $$v)
                      },
                      expression: "form.odomUpkeep",
                    },
                  }),
                ],
                1
              ),
              _c(
                "a-form-model-item",
                { attrs: { label: "保养门店名称", prop: "upkeepStore" } },
                [
                  _c("a-input", {
                    attrs: { placeholder: "请输入保养门店名称" },
                    model: {
                      value: _vm.form.upkeepStore,
                      callback: function ($$v) {
                        _vm.$set(_vm.form, "upkeepStore", $$v)
                      },
                      expression: "form.upkeepStore",
                    },
                  }),
                ],
                1
              ),
              _c(
                "a-form-model-item",
                { attrs: { label: "保养地址", prop: "upkeepAddress" } },
                [
                  _c("a-input", {
                    attrs: { placeholder: "请输入保养地址" },
                    model: {
                      value: _vm.form.upkeepAddress,
                      callback: function ($$v) {
                        _vm.$set(_vm.form, "upkeepAddress", $$v)
                      },
                      expression: "form.upkeepAddress",
                    },
                  }),
                ],
                1
              ),
              _c(
                "a-form-model-item",
                { attrs: { label: "车辆保养费用", prop: "upkeepCost" } },
                [
                  _c("a-input", {
                    attrs: { placeholder: "请输入车辆保养费用" },
                    model: {
                      value: _vm.form.upkeepCost,
                      callback: function ($$v) {
                        _vm.$set(_vm.form, "upkeepCost", $$v)
                      },
                      expression: "form.upkeepCost",
                    },
                  }),
                ],
                1
              ),
              _c(
                "a-form-model-item",
                { attrs: { label: "保养处理人", prop: "upkeepPerson" } },
                [
                  _c("a-input", {
                    attrs: { placeholder: "请输入保养处理人" },
                    model: {
                      value: _vm.form.upkeepPerson,
                      callback: function ($$v) {
                        _vm.$set(_vm.form, "upkeepPerson", $$v)
                      },
                      expression: "form.upkeepPerson",
                    },
                  }),
                ],
                1
              ),
              _c(
                "a-form-model-item",
                { attrs: { label: "备注", prop: "remark" } },
                [
                  _c("a-input", {
                    attrs: { type: "textarea", placeholder: "请输入备注" },
                    model: {
                      value: _vm.form.remark,
                      callback: function ($$v) {
                        _vm.$set(_vm.form, "remark", $$v)
                      },
                      expression: "form.remark",
                    },
                  }),
                ],
                1
              ),
              _c("div", { staticStyle: { height: "60px", width: "100%" } }),
              _c(
                "div",
                {
                  staticClass: "bottom-control",
                  staticStyle: { "text-align": "center" },
                },
                [
                  _c(
                    "a-space",
                    [
                      _c(
                        "a-button",
                        {
                          attrs: { type: "primary" },
                          on: { click: _vm.submitForm },
                        },
                        [_vm._v(" 保存 ")]
                      ),
                      _c(
                        "a-button",
                        {
                          attrs: { type: "dashed" },
                          on: { click: _vm.cancel },
                        },
                        [_vm._v(" 取消 ")]
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
<!--
 * @Author: linjituan linjituan@revolution.com
 * @Date: 2024-10-16 16:42:32
 * @LastEditors: linjituan linjituan@revolution.com
 * @LastEditTime: 2024-11-04 15:55:30
 * @FilePath: \ra-web-admin\src\views\iot\upkeep\modules\register.vue
 * @Description: 这是默认设置,请设置`customMade`, 打开koroFileHeader查看配置 进行设置: https://github.com/OBKoro1/koro1FileHeader/wiki/%E9%85%8D%E7%BD%AE
-->
<template>
  <vxe-modal
    v-model="visible"
    width="35%"
    height="700px"
    :title="title"
    @close="handleCloseDeviceBind"
    show-zoom
    resize
    :lock-view="false"
    :mask="false"
    :esc-closable="true"
    :position="{ left: '30%' }"
    remember
    transfer
    ref="manyVehicleMonitorRef"
  >
    <div class="register-contaion">
      <a-form-model ref="form" :model="form" :rules="rules">
        <a-form-model-item label="当前保养时间" prop="upkeepTime">
          <a-date-picker
            placeholder="请输入当次保养时的时间"
            style="width: 100%"
            v-model="form.upkeepTime"
            value-format="YYYY-MM-DD HH:mm:ss"
            format="YYYY-MM-DD HH:mm:ss"
            allow-clear
          />
        </a-form-model-item>
        <a-form-model-item label="当前保养里程(KM)" prop="odom">
          <a-input v-model="form.odom" placeholder="请输入保养时的里程" />
        </a-form-model-item>
        <a-form-model-item label="下次保养时间周期(月)" prop="cycleUpkeep">
          <a-input v-model="form.cycleUpkeep" placeholder="请输入下次保养所需时间周期" />
        </a-form-model-item>
        <a-form-model-item label="下次保养里程周期(KM)" prop="odomUpkeep">
          <a-input v-model="form.odomUpkeep" placeholder="请输入下次车辆保养里程周期" />
        </a-form-model-item>
        <a-form-model-item label="保养门店名称" prop="upkeepStore">
          <a-input v-model="form.upkeepStore" placeholder="请输入保养门店名称" />
        </a-form-model-item>
        <a-form-model-item label="保养地址" prop="upkeepAddress">
          <a-input v-model="form.upkeepAddress" placeholder="请输入保养地址" />
        </a-form-model-item>
        <a-form-model-item label="车辆保养费用" prop="upkeepCost">
          <a-input v-model="form.upkeepCost" placeholder="请输入车辆保养费用" />
        </a-form-model-item>
        <a-form-model-item label="保养处理人" prop="upkeepPerson">
          <a-input v-model="form.upkeepPerson" placeholder="请输入保养处理人" />
        </a-form-model-item>
        <a-form-model-item label="备注" prop="remark">
          <a-input type="textarea" v-model="form.remark" placeholder="请输入备注" />
        </a-form-model-item>
        <div style="height: 60px; width: 100%"></div>
        <div class="bottom-control" style="text-align: center">
          <a-space>
            <a-button type="primary" @click="submitForm"> 保存 </a-button>
            <a-button type="dashed" @click="cancel"> 取消 </a-button>
          </a-space>
        </div>
      </a-form-model>
    </div>
  </vxe-modal>
</template>

<script>
import { addRecord } from '@/api/iot/record'
export default {
  data() {
    return {
      visible: false,
      title: '保养信息登记',
      form: {
        vehicleId: '',
        upkeepTime: '',
        cycleUpkeep: '',
        odom: '',
        odomUpkeep: '',
        remark: ''
      },
      rules: {
        upkeepTime: [{ required: true, message: '请输入保养时间', trigger: 'change' }],
        cycleUpkeep: [{ required: true, message: '请输入保养周期', trigger: 'blur' }],
        odom: [{ required: true, message: '请输入保养里程', trigger: 'blur' }],
        odomUpkeep: [{ required: true, message: '请输入下次保养里程', trigger: 'blur' }]
      }
    }
  },
  created() {},
  methods: {
    submitForm() {
      this.$refs.form.validate((valid) => {
        if (valid) {
          addRecord(this.form)
            .then((res) => {
              this.$message.success('新增成功', 3)
              this.visible = false
              //   this.$emit('ok')
            })
            .catch((err) => {
              console.log(err)
            })
        } else {
          return false
        }
      })
    },
    cancel() {
      this.$refs.form.resetFields()
      this.$refs.manyVehicleMonitorRef.close()
    },
    open(record) {
      this.visible = true
      this.form.upkeepId = record.id
      this.form.cycleUpkeep = record.cycleUpkeep
      this.form.odomUpkeep = record.odomUpkeep
      this.form.vehicleId = record.vehicleId
    }
  }
}
</script>

<style lang="less" scoped>
.bottom-control {
  position: absolute;
  bottom: 0;
  width: 100%;
  left: 0;
  right: 0;
  border-bottom-left-radius: 10px;
  border-bottom-right-radius: 10px;
}
</style>
